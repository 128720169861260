.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  background-image: url("https://static01.nyt.com/images/2021/05/04/sports/03NBA-jokic-print/03NBA-jokic-superJumbo.jpg");
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  /* added these properties to make image fit screen nicely*/
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* stuff i added */

[type="search"] {
  background-color: transparent;
  font-size: 24px;
  color: white;
  /* border: none; */
  outline: none;
  /* border-bottom: 3px solid var(--blue); */
  border-width: 2px;
  padding: 5px 0 0 0;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-color: white;
  /* transition: border-color 300ms; */
 }

 [type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  appearance: none;
  height: 10px;
  width: 10px;
  background-image: url(./whitex.png);
  background-size: 10px 10px;
}

 /* [type="search"]:focus {
  border-color: black;
} */

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
  font-size: 1.4rem;
}

[placeholder]:focus::-webkit-input-placeholder {
  transition: text-indent 0.4s 0.4s ease; 
  text-indent: -110%;
  opacity: 1;
}

input {
  font-family: 'Oswald', sans-serif;
}

.search-overlay {
  position: relative;
}

.search-dropdown {
  position: absolute;
  top: 100%;
  left: -100px;
  width: 200px;
  text-align: left;
  background-color: #fff;
  /* border: 1px solid #ccc; */
  border-top: none;
  max-height: 200
}

.search-dropdown p {
  font-family: Arial, Helvetica, sans-serif;
  text-shadow: none;
  color: black;
  margin: 5px;

}

.search-dropdown p:hover {
  background-color: #f8f9fa;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}